var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[_c('Select',{staticClass:"mb-2",attrs:{"axis":'col',"labelWidth":'100%',"selectStyle":'w-full',"shouldDisplayAnyway":true,"fetchOptions":{
			endpoint: 'admin/solution',
			params: {
				select: ['id', 'name'],
				join: 'category||id,isAvailabilityInputActive',
				s: {
					'category.isAvailabilityInputActive': true,
				},
			},
			nameKey: 'name',
			valueKey: 'id',
		},"selectLabel":'Solução'},on:{"input":_vm.applyFilter},model:{value:(_vm.solutionIdFilter),callback:function ($$v) {_vm.solutionIdFilter=$$v},expression:"solutionIdFilter"}}),_c('div',{staticClass:"h-full relative py-1"},[_c('div',{staticClass:"full-calendar-loading",attrs:{"id":"availabilities-calendar-loading"}},[_c('BOverlay',{attrs:{"show":true,"z-index":"9001","no-wrap":true}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.solutionIdFilter),expression:"!solutionIdFilter"}],staticClass:"\n\t\t\t\tabsolute\n\t\t\t\th-full\n\t\t\t\tw-full\n\t\t\t\tleft-0\n\t\t\t\ttop-0\n\t\t\t\tflex\n\t\t\t\tjustify-center\n\t\t\t\titems-center\n\t\t\t\tz-50\n\t\t\t\tbg-journey-light-gray\n\t\t\t\topacity-80\n\t\t\t"},[_c('p',{staticClass:"m-0 p-0 text-2xl"},[_vm._v("Selecione uma solução.")])]),_c('FullCalendar',{ref:"fullCalendar",attrs:{"options":_vm.calendarOptions}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }