



















































import { Component, Vue } from "vue-property-decorator";

import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import Select from "@/layouts/components/Select.vue";
import { AgendaCoreMixin } from "../../layouts/mixins/agenda_core";
import { BOverlay } from "bootstrap-vue";
import { crudGet } from "@/api/_request";

let persistentSolutionIdFilter = "";
function fetchEvents(range: any, params: any = {}) {
	if (!Object.keys(params).length) {
		return [];
	}
	return crudGet("admin", "availability", {
		solution: {
			...params, //filter params specified in "filtersMap"
		},
		shouldFormatAsCalendarEvent: true,
		range,
	});
}

const filtersMap = {
	id: "solutionIdFilter",
};

@Component({
	components: {
		FullCalendar,
		BOverlay,
		Select,
	},
	mixins: [AgendaCoreMixin("availabilities", fetchEvents, filtersMap)],
})
export default class AvailabilitiesAgenda extends Vue {
	solutionIdFilter = "";
	isSolutionSearchLoading = false;

	async mounted() {
		if (persistentSolutionIdFilter) {
			await this.$nextTick(); //awaits for automatically fetchEvents call for loading consistency
			this.solutionIdFilter = persistentSolutionIdFilter;
			this.applyFilter();
		}
	}

	async applyFilter() {
		persistentSolutionIdFilter = this.solutionIdFilter;
		//from mixin
		await (<any>this).applyFilters();
	}
}
